body {
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 100vh;
	box-sizing: border-box;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	*, *:before, *:after {
		box-sizing: inherit;
	}
	
	#root {
		width: 100%;
		min-height: 100vh;
	}
	
	.App {
		width: 100%;
		min-height: 100vh;
		position: relative;
		top: 0;
		margin: 0;
		padding: 0;
		background-position: left top;
		background-size: cover;
		
		.content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: inline-block;
			background: #fff;
			padding: 20px;
			width: 550px;
			max-width: 95%;
			border-radius: 4px;
			
			.logo-container {
				width: auto;
				text-align: center;
				margin-bottom: 15px;
				
				.logo {
					margin: 0 auto;
					width: 100px;
					border-radius: 4px;
				}
			}
			
			h1 {
				margin-bottom: 10px;
			}
			
			.button-container {
				.btn {
					margin: 0 5px;
				}
			}
			
			.license-container {
				margin-bottom: 10px;
				
				p {
					margin-bottom: 0;
				}
				
				.license-container-input {
					width: 350px;
					max-width: 90%;
					margin: 0 auto;
					text-align: center;
				}
			}
			
			.footer {
				margin-top: 50px;
				font-style: italic;
				font-size: 0.8em;
				
				p {
					margin: 0;
				}
			}
		}
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}
